import classNames from "classnames";
import React from "react";
import Icon, { IconType } from "../Icon";
import Pill from "../Pill";

interface BadgeProps {
  title: string;
  iconType: IconType;
  label?: Boolean;
  className?: string;
}
const Badge: React.FC<BadgeProps> = props => {
  const { iconType, title, label, className } = props;
  return (
    <div
      className={classNames(
        "bg-white rounded-lg shadow-xl flex flex-row items-center max-w-max px-3 py-2",
        className
      )}
    >
      <Icon iconType={iconType} />
      <p className="font-semibold text-base text-gray-3-dark ml-3">{title}</p>
      {label && <Pill text={"Beta"} />}
    </div>
  );
};

export default Badge;
