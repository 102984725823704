import classNames from "classnames";
import React from "react";
import Icon, { IconType } from "../Icon";

export enum BulletVariant {
  white = "white",
  blue = "blue"
}

export enum BulletSize {
  default = "default",
  large = "large",
  small = "small"
}

interface BulletProps {
  iconType: IconType;
  size?: BulletSize;
  variant?: BulletVariant;
}
const Bullet: React.FC<BulletProps> = props => {
  const {
    iconType,
    size = BulletSize.default,
    variant = BulletVariant.blue
  } = props;
  return (
    <span
      className={classNames("rounded-full inline-block", {
        "bg-white shadow-xl": variant === BulletVariant.white,
        "bg-blue-3-light": variant === BulletVariant.blue,
        "p-5 h-16 w-16": size === BulletSize.large,
        "p-3": size === BulletSize.small,
        "p-4": size === BulletSize.default
      })}
    >
      <Icon iconType={iconType} className="m-auto"/>
    </span>
  );
};

export default React.memo(Bullet);
