import classNames from "classnames";
import React from "react";

export interface BrandObject {
  src: Object;
  filter?: boolean;
  onClick?: () => void;
}

interface BrandProps {
  brandsList: BrandObject[];
  center?: boolean;
}

const Brands: React.FC<BrandProps> = props => {
  // brandsList is an array of objects with 2 properties - src and filter. if filter is true -> grayscale is applied.
  // eg: brandsList = [{src: <Cartograph/>, filter: false}, {src: <Pmg/>, filter: true}]
  const { brandsList, center } = props;
  return (
    <div
      className={classNames("flex justify-center md:justify-between flex-wrap", {
        "space-x-8 lg:space-x-13": center
      })}
    >
      {brandsList &&
        brandsList.map((brand, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "flex my-7 lg:my-10 justify-evenly items-center",
                {
                  "w-1/2 md:w-1/3 lg:w-1/4": !center,
                  "cursor-pointer": brand?.onClick,
                  grayscale: brand?.filter && !brand?.onClick,
                  "grayscale-hover": brand?.filter && brand?.onClick
                }
              )}
              onClick={brand?.onClick}
            >
              {brand.src}
            </div>
          );
        })}
    </div>
  );
};

export default Brands;
