import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

const SideBox: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      snowflake: file(relativePath: { eq: "agencies/snowflake.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      google: file(relativePath: { eq: "agencies/google.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      amazon: file(relativePath: { eq: "agencies/amazon.png" }) {
        childImageSharp {
          fixed(width: 190) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className="w-96 md:w-full lg:w-80 text-center p-12 rounded-md border border-gray-4-light">
      <p className="text-2xl text-gray-2-light font-semibold px-4 mb-12">
        {t("Enterprise Data Integrations")}
      </p>
      <div className="pt-12 border-t border-gray-4-light flex flex-col md:flex-row lg:flex-col justify-between items-center space-y-10 md:space-y-0 lg:space-y-10">
        <Img fixed={data?.snowflake?.childImageSharp?.fixed} alt="Snowflake" />
        <Img
          fixed={data?.google?.childImageSharp?.fixed}
          alt="Gooogle Big Query"
        />
        <Img
          fixed={data?.amazon?.childImageSharp?.fixed}
          alt="Amazon Redshift"
        />
      </div>
    </div>
  );
};

export default SideBox;
