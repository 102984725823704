import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import EcommerceListItem from "./EcommerceListItem";

const EcommerceSection: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  const pageQuery = useStaticQuery(graphql`
    query {
      ecommerce: file(relativePath: { eq: "agencies/ecommerce.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pmg: file(relativePath: { eq: "agencies/pmg.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pmgCeo: file(relativePath: { eq: "agencies/pmg-ceo-1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const ecommerce = getImage(pageQuery.ecommerce);
  const pmg = getImage(pageQuery.pmg);
  const pmgCeo = getImage(pageQuery.pmgCeo);

  return (
    <div className="bg-gradient-to-r from-gray-6 to-gray-5 rounded-tl-large rounded-br-large">
      <Section>
        <div className="flex lg:flex-row flex-col-reverse py-20 lg:py-36 items-center">
          <div className="w-full lg:w-1/2">
            <div className="mt-14 md:mt-20 lg:mt-0 lg:mr-24 relative overflow-hidden"
            style={{
              width: "90%",
              height: "50%",
              borderTopRightRadius: "6px",
              borderBottomLeftRadius: "6px",
              borderTopLeftRadius: "120px",
              borderBottomRightRadius: "120px",
              overflow: "hidden",
            }}> 
            <div className="absolute top-0 left-0 z-10">
              <GatsbyImage image={ecommerce} alt={""} />
            </div>
                <div
                  className="relative z-20"
                  style={{
                    background: `linear-gradient(to right, rgba(66,165,245,0.1), rgba(66,165,245,0.9))`,
                    width: "100%",
                    height: "100%",
                    alignItems: "flex-start",
                    padding: "52px",
                    overflow: "hidden",
                  }}
                >
                  <div className="w-48">
                    <GatsbyImage image={pmg} alt={""} />
                  </div>
                  <p className="text-2xl font-semibold text-white pt-14">
                    “
                    {t(
                      "Perpetua is a critical part of our approach to the marketplace. The relationship was founded on our mutual agility and reliance on focused innovation. The platform is different. Their team is different. This difference has them leading the way and we want to be there with them"
                    )}
                    ”
                  </p>
                  <div className="flex justify-start items-center pt-14">
                    <div className="w-24">
                      <GatsbyImage image={pmgCeo} alt={""} />
                    </div>
                    <p className="text-base font-medium text-white px-4">
                      <span className="font-semibold text-lg">
                        Price Glomski
                      </span>
                      <p>Executive Vice President @ PMG</p>
                    </p>
                  </div>
                </div>
            </div>
          </div>

          {/* heading and list items */}
          <div className="w-full lg:w-1/2">
            <Header
              className="text-gray-3-dark text-center md:text-left"
              variant={HeaderVariant.h2}
            >
              {t(
                "Perpetua meets the needs of growth-obsessed eCommerce agencies"
              )}
            </Header>
            <EcommerceListItem
              text={t(
                "Streamline cumbersome, repetitive workflows with AI-powered smart recommendations that learn over time"
              )}
            />
            <EcommerceListItem
              text={t(
                "Provide maximum transparency and visibility with realtime client-facing reporting and customizable dashboards"
              )}
            />
            <EcommerceListItem
              text={t(
                "Manage, measure and optimize across eCommerce channels, marketplaces and ad units directly in Perpetua"
              )}
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default EcommerceSection;
