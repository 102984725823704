import React from "react";
import classNames from "classnames";
import { IconType } from "../../../../components/Icon";
import Bullet, {
  BulletSize,
  BulletVariant
} from "../../../../components/Bullet";

interface CardProps {
  iconType: IconType;
  title: string;
  description: string;
  className?: string;
}
const Card: React.FC<CardProps> = props => {
  const { iconType, title, description, className } = props;
  return (
    <span className={classNames("", className)}>
      <Bullet
        iconType={iconType}
        size={BulletSize.default}
        variant={BulletVariant.blue}
      />
      <p className="text-gray-3-dark font-semibold text-2xl mt-5 mb-3">
        {title}
      </p>
      <p className="text-gray-1 text-lg font-medium">{description}</p>
    </span>
  );
};

export default Card;
