import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Badge from "../../../components/Badge";
import Button, { ButtonSize, ButtonVariant } from "../../../components/Button";
import Header, { HeaderVariant } from "../../../components/Header";
import Icon, { IconType } from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import { CTA } from "../../../constants/text";
import Section from "../../../containers/Section";
import Brands, { BrandObject } from "../../Brands";
// import brand svgs
import BraveOne from "../../Brands/BrandSvgs/braveone.svg";
import Cartograph from "../../Brands/BrandSvgs/cartograph.svg";
import Ignite from "../../Brands/BrandSvgs/ignite.svg";
import Incrementum from "../../Brands/BrandSvgs/incrementum.svg";
import Pmg from "../../Brands/BrandSvgs/pmg.svg";
import Podean from "../../Brands/BrandSvgs/podean.svg";
import Power from "../../Brands/BrandSvgs/power.svg";
import Video from "./Video";

const HeroSection: React.FC<{}> = (props) => {
  // state to control video loading
  const [load, setLoad] = React.useState<Boolean>(false);

  const { t } = useTranslation();

  // array of brand svgs
  const brandsList: Array<BrandObject> = [
    { src: <Cartograph />, filter: true },
    { src: <Podean />, filter: true },
    { src: <Pmg />, filter: true },
    { src: <Incrementum />, filter: true },
    { src: <Ignite />, filter: true },
    { src: <BraveOne />, filter: true },
    { src: <Power />, filter: true },
  ];

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "agencies/hero.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const image = getImage(data.hero);

  return (
    <div className="bg-gradient-to-b from-white via-gray-5 to-gray-6 rounded-br-large">
      <Section>
        <div className="relative py-16">
          <div className="flex justify-center md:justify-start">
            <Badge
              iconType={IconType.union}
              title={t("Perpetua for Agencies")}
              className="mb-12"
            />
          </div>

          <div className="flex flex-col lg:flex-row">
            <span className="text-center md:text-left content-center md:justify-start w-full lg:w-1/2 ">
              <Header className="text-gray-3-dark" variant={HeaderVariant.h2}>
                {t("eCommerce Advertising Software Designed for Agencies")}
              </Header>
              <Paragraph className="text-gray-3-dark">
                {t(
                  "Differentiate your agency with eCommerce advertising optimization and intelligence software. Spend less time managing campaigns and more time driving client success."
                )}
              </Paragraph>
              <Button
                id="agencies-header-partnerprogram"
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                text={t(CTA.JoinPartner)}
                className="mt-12 md:mt-10 md:mx-0 md:w-auto w-full mx-auto block"
              />
            </span>
            <span className="relative w-full lg:w-1/2 lg:mt-0 lg:ml-20 md:mt-20 mt-16">
              <div className="relative">
                <div className="rounded-xl overflow-hidden">
                  <GatsbyImage image={image} alt={""} />
                </div>
                {!load && (
                  <div className=" bg-white absolute -bottom-6 md:right-6 right-1/2 -mr-24 md:-mr-0 p-4 rounded-lg shadow-xl">
                    <Icon iconType={IconType.cartograph} />
                  </div>
                )}
                <div onClick={() => setLoad(true)}>
                  <Icon
                    iconType={IconType.play}
                    className="absolute top-1/2 left-1/2 -mt-9 -ml-9 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                  />
                </div>
                {load && (
                  <Video className="absolute top-0 left-0 w-full h-full" />
                )}
              </div>
            </span>
          </div>
          <div className="mt-20 md:mt-24 lg:mt-20">
            <Brands brandsList={brandsList} />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default HeroSection;
