import React from "react";
import { IconType } from "../../../../components/Icon";
import Card from "../Card";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const CardSection: React.FC<{}> = props => {
  const {t} = useTranslation();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-16 lg:gap-y-20 md:gap-x-6 lg:gap-x-24 text-center md:text-left">
      <Card
        iconType={IconType.rocket}
        title={t("Activate")}
        description={t("Automatically create campaigns for Instacart Featured Ads, Amazon Sponsored Ads, or Amazon DSP.")}
      />
      <Card
        iconType={IconType.lightbulb}
        title={t("Measure")}
        description={t("Get advanced insights to holistic campaign performance across marketplaces and ad units. Access category benchmarks to better inform advertising strategies.")}
      />
      <Card
        iconType={IconType.optimize}
        title={t("Optimize")}
        description={t("Set your goals and rely on Perpetua's AI-powered bid and budget optimization for maximum efficiency. Strategic levers like budget pacing and forecasting help you scale ad spend to meet client budgets.")}
      />
      <Card
        iconType={IconType.sales}
        title={t("Report")}
        description={t("Keep teams aligned with Perpetua's activity stream and approvals designed for any agency model. Maximize client visibility with custom whitelabel dashboards, enterprise data integrations and Amazon Marketing Cloud.")}
      />
    </div>
  );
};

export default CardSection;
