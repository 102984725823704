import React from "react";
import Section from "../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../Button";

export enum BottomCTAVariant {
  primary = "primary",
  secondary = "secondary"
}
interface ButtonCTAProps {
  title: string;
  subtitle?: string | React.ReactElement;
  variant?: string;
  primaryButtonText?: string;
  primaryButtonId?: string;
  secondaryButtonText?: string;
  secondaryButtonId?: string;
  primaryButtonOnClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  secondaryButtonOnClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const BottomCTA: React.FC<ButtonCTAProps> = props => {
  const {
    title,
    subtitle,
    variant = BottomCTAVariant.primary,
    primaryButtonText = "Get Started",
    secondaryButtonText = "Request a Demo",
    primaryButtonId,
    secondaryButtonId,
    primaryButtonOnClick,
    secondaryButtonOnClick
  } = props;
  return (
    <Section>
      <div className="flex-col items-center justify-center bg-gradient-to-b from-indigo-1 to-indigo-1-dark shadow rounded-xl text-center py-20 md:py-24 lg:py-32">
        <div
          className="m-auto max-w-full px-9 md:max-w-xs lg:max-w-5xl md:px-0"
        >
          <h2 className="text-4xl font-semibold text-white lg:text-5xl lg:font-bold">
            {title}
          </h2>
          <p className="text-xl font-normal text-indigo-2-light pt-3 lg:pt-5">
            {subtitle}
          </p>
          <div className="flex flex-col items-center justify-center space-y-4 mt-10 lg:flex-row lg:space-y-0 lg:mt-8 lg:space-x-4">
            {variant === BottomCTAVariant.primary && (
              <>
                <Button
                  id={primaryButtonId}
                  text={primaryButtonText || "Get Started"}
                  variant={ButtonVariant.primaryWhite}
                  size={ButtonSize.large}
                  className="w-full lg:w-max"
                  onClick={primaryButtonOnClick}
                />
                <Button
                  id={secondaryButtonId}
                  text={secondaryButtonText || "Request a Demo"}
                  variant={ButtonVariant.secondaryWhite}
                  size={ButtonSize.large}
                  className="w-full lg:w-max"
                  onClick={secondaryButtonOnClick}
                />
              </>
            )}
          </div>
          {variant === BottomCTAVariant.secondary && (
            <div className="flex flex-col items-center justify-center space-y-4 mt-10 lg:flex-row lg:space-y-0 lg:mt-8 lg:space-x-4">
              <Button
                id={secondaryButtonId}
                text={secondaryButtonText}
                variant={ButtonVariant.primaryWhite}
                size={ButtonSize.large}
                className="w-full lg:w-max"
                onClick={secondaryButtonOnClick}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default BottomCTA;
