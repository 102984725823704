import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Header, { HeaderVariant } from "../../../components/Header";
import Paragraph from "../../../components/Paragraph";
import Section from "../../../containers/Section";
import CardSection from "./CardSection";
import SideBox from "./SideBox";

const EmpowermentSection: React.FC<{}> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      empowerment: file(relativePath: { eq: "agencies/empowerment.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  
  const image = getImage(data.empowerment);
  const { t } = useTranslation();

  return (
    <Section>
      {/* Heading, Desc and Image */}
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-24 py-20 lg:py-36">
        <div className="text-center md:text-left">
          <Header className="text-gray-3-dark" variant={HeaderVariant.h2}>
            {t(
              "Empowerment software for the trusted partners of ambitious brands"
            )}
          </Header>
          <Paragraph className="md:text-2xl md:font-semibold text-gray-3-dark">
            {t(
              "Top agencies need the best tools. Perpetua's eCommerce advertising software brings simplicity to complex workflows. Activate, measure, optimize and report - all in one place."
            )}
          </Paragraph>
        </div>
        <div className="overflow-hidden rounded-br-large rounded-md mt-16 md:mt-20 lg:mt-0">
          <GatsbyImage image={image} alt={""} />
        </div>
      </div>

      {/* Cards and Side Box */}
      <div className="flex lg:flex-row lg:justify-between lg:content-center md:items-stretch items-center flex-col pb-20 lg:pb-36">
        <CardSection />
        <span className="lg:ml-44 lg:mt-0 md:mt-20 mt-16">
          <SideBox />
        </span>
      </div>
    </Section>
  );
};

export default EmpowermentSection;
