import React from "react";
import Section from "../../../containers/Section";
import ListCard from "./ListCard";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const DayoneSection: React.FC<{}> = props => {
  const {t} = useTranslation();
  return (
    <Section className="lg:px-0">
      <div className="flex flex-col py-20 lg:py-36 justify-center">
        <div className="text-4xl font-semibold text-gray-3-dark text-center mb-14 md:mb-20 lg:mb-24 lg:px-40">
          {t("From day one, our team makes onboarding and setup easy. Perpetua partners get white-glove service, with ongoing support to ensure you're continuously meeting your growth goals.")}
        </div>

        <div className="flex flex-col lg:flex-row">
          <ListCard
            title={t("Onboarding")}
            bullets={[
              t("Personalized training with zero client downtime."),
              t("Client account audit and strategic account success framework."),
              t("Bespoke courses and materials for your team and the unique needs of your clients.")
            ]}
            className="my-6 lg:my-0 lg:mx-5 w-full lg:flex-1"
          />
          <ListCard
            title={t("Ongoing Support")}
            bullets={[
              t("Regular business and strategy reviews."),
              t("Dedicated Data Strategist for your agency."),
              t("Access to Perpetua Ad School and the Perpetua Slack community.")
            ]}
            className="my-6 lg:my-0 lg:mx-5 w-full lg:flex-1"
          />
          <ListCard
            title={t("Acceleration and Optimization")}
            bullets={[
              t("Insight and opportunities to collaborate on the Perpetua product roadmap."),
              t("Co-marketing opportunities."),
              t("Listing on our preferred agency network.")
            ]}
            className="my-6 lg:my-0 lg:mx-5 w-full lg:flex-1"
          />
        </div>
      </div>
    </Section>
  );
};

export default DayoneSection;
