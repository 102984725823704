import { PageProps, graphql } from "gatsby";
import React from "react";
import DayoneSection from "../components/AgencyPage/DayoneSection";
import EcommerceSection from "../components/AgencyPage/EcommerceSection";
import EmpowermentSection from "../components/AgencyPage/EmpowermentSection";
import HeroSection from "../components/AgencyPage/HeroSection";
import BottomCTA, { BottomCTAVariant } from "../components/BottomCta";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import SEO from "../components/Seo";
import { CTA } from "../constants/text";
import { Intercom } from "../constants/destinations";
import Layout from "../containers/Layout";

import { Name, Type, Location } from "../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AgenciesPage: React.FC<PageProps> = props => {
  const {t} = useTranslation();
  
  return (
    <Layout primaryButtonId="agencies-nav-getstarted">
      <SEO
        title="eCommerce Advertising Software for Agencies"
        description="Perpetua provides eCommerce advertising and intelligence software for growth-obsessed agencies."
      />
      <NavigationBarSpacer />
      <HeroSection />
      <EmpowermentSection />
      <EcommerceSection />
      <DayoneSection />
      <BottomCTA
        secondaryButtonId="agencies-bottom-partnerprogram"
        variant={BottomCTAVariant.secondary}
        title={t("Flexible, custom pricing tailored to your agency.")}
        secondaryButtonText={t(CTA.JoinPartner)}
      />
    </Layout>
  );
};

export default AgenciesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
