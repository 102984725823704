import classNames from "classnames";
import React from "react";
export enum HeaderVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h5 = "h5"
}

interface HeaderProps extends React.HTMLAttributes<HTMLHeadElement> {
  variant: HeaderVariant;
}
const Header: React.FC<React.PropsWithChildren<HeaderProps>> = props => {
  const { variant, children, className, ...rest } = props;
  switch (variant) {
    case HeaderVariant.h1:
      return (
        <h1
          className={classNames(
            "text-4xl font-semibold mb-6 md:text-6xl md:font-bold md:mb-8",
            className
          )}
          {...rest}
        >
          {children}
        </h1>
      );
    case HeaderVariant.h2:
      return (
        <h2
          className={classNames(
            "text-4xl font-semibold mb-6 md:text-5xl md:font-bold md:mb-8",
            className
          )}
        >
          {children}
        </h2>
      );
    case HeaderVariant.h3:
      return (
        <h3 className={classNames("text-4xl font-semibold", className)}>
          {children}
        </h3>
      );
    case HeaderVariant.h5:
      return (
        <h5 className={classNames("text-2xl font-semibold", className)}>
          {children}
        </h5>
      );
    default:
      return (
        <div className={className} {...rest}>
          {children}
        </div>
      );
  }
};

export default Header;
